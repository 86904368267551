<template>
  <default-layout :hide-header="true">
    <div class="welcome-page">

      <a href="https://wa.me/77770089070?text" target="_blank" class="nav-item">
      <p class="w-title">{{ $t('insurance') }} <span class="float-end icon-message"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M9.99781 1C5.02945 1 1 5.02945 1 9.99781C1 11.5626 1.39121 13.1275 2.17363 14.4967L1 18.9174L5.42066 17.7437C9.68484 20.2866 15.2009 18.8391 17.7437 14.575C20.2866 10.3108 18.8391 4.79473 14.575 2.25187C13.1666 1.43033 11.6018 1 9.99781 1Z" stroke="#2AA65C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.5" d="M14 10C14 12.2 12.2 14 10 14C7.8 14 6 12.2 6 10" stroke="#2AA65C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg></span></p> 
      </a>

<!--      <template v-if="bannerLoading">-->
<!--        <div>-->
<!--          <div class="primary-block banner-loading">-->
<!--            <div class="w-25">-->
<!--              <h2 class="title skeleton skeleton-text"></h2>-->
<!--              <h2 class="title skeleton skeleton-text"></h2>-->
<!--              <div class="gray-btn skeleton w-75"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
      <section class="insurance-types">
        <template v-if="!insuranceTypeLoading">
          <router-link
              :to="{name: 'insurance.types', params:{id: type.id}}"
              class="insurance-types__item"
              @click.native="productsClicked(type.id)"
              v-for="type in insuranceTypes"
              :key="type.id"
              :style="`background:${type.bgColor ? type.bgColor : '#fff'}`"
          >
            <div class="insurance-types__item__container">

              <h2 class="title">{{ type.mainTitle }}</h2>
              <h5 class="subtitle">{{ type.mainSubtitle }}</h5>
              <div class="gallery">
                <span class="discount-badge" v-if="type.hasDiscount">{{ type.discountValue }}%</span>
                <img :src="type.live_icon" alt="type.title" class="img">
              </div>
            </div>
          </router-link>
        </template>
        <template v-if="insuranceTypeLoading">
          <div class="insurance-types__item"
               v-for="item in 6"
               :key="item"
          >
            <h2 class="title skeleton mb-3 skeleton-text"></h2>
            <div class="icon-box skeleton float-end"></div>
          </div>
        </template>
      </section>
    </div>
  </default-layout>
</template>

<script>

import TheStories from "@/components/TheStories";
import eventLogger from '../../services/eventLogger';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Welcome",
  // components: {TheStories, VueSlickCarousel},
  data() {
    return {
      insuranceTypes: [],
      insuranceTypeLoading: true,
      banner: null,
      bannerLoading: true,
      options: {
        "arrows": false,
        "dots": true,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 700,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "lazyLoad": "ondemand",
        "autoplay": true,
        "autoplaySpeed": 10000,
        // "touchMove": false,
        "draggable": false,
      },

    }
  },
  computed: {
    ...mapGetters({
      authStatus: 'status'
    }),



  },
  created() {
    // this.getActiveLifeAgreements();
  },
  mounted() {
    eventLogger('view_landing_screen')
    this.getInsuranceTypes();
    //   this.getBanner();
    if (this.authStatus) {
      console.log(this.authStatus)
      this.getActiveLifeAgreements();
      this.getActiveAgreements();
      this.getActiveAgreementsSubscription();
      this.getPolicies()

      this.getArchiveLifeAgreements();
      this.getArchiveAgreements();
      this.getArchiveAgreementsSubscription();
    }


  },
  methods: {
    async getInsuranceTypes() {
      try {
        const {data} = await window.axios.get('insurance-types/main');
        this.insuranceTypes = data;
        this.insuranceTypeLoading = false;
      } catch (e) {
        throw e;
      }
    },
    async getBanner() {
      try {
        const {data} = await window.axios.get('main-page-banners');
        this.banner = data;
        this.bannerLoading = false;
      } catch (e) {
        throw e;
      }
    },
    ...mapActions({
      signInByCaskey:'signInByCaskey',
      getActiveLifeAgreements: 'agreements/getActiveLifeAgreements',
      getActiveAgreementsSubscription: 'agreements/getActiveAgreementsSubscription',
      getActiveAgreements: 'agreements/getActiveAgreements',
      getPolicies: 'agreements/getPolicies',

      getArchiveLifeAgreements: 'agreementsArchive/getArchiveLifeAgreements',
      getArchiveAgreementsSubscription: 'agreementsArchive/getArchiveAgreementsSubscription',
      getArchiveAgreements: 'agreementsArchive/getArchiveAgreements',
      getArchivePolicies: 'agreementsArchive/getArchivePolicies',
    }),
    authLink() {

      let route =  this.$router.resolve({name: 'Welcome'});
      let baseUrl = this.$store.getters.appUrl + route.href;
      let auth = localStorage.getItem('AUTH')

      if (!this.authStatus) {
        baseUrl = `${this.$store.getters.hbUrl}${baseUrl}`
      }else{
        localStorage.setItem('AUTH',1)
      }
      if(!auth){
        location.href = baseUrl
      }

    },

    mainBannerClicked(url) {
      eventLogger('select_main_banner');

      let baseUrl = url;

      if (!this.authStatus && this.$store.getters.isNewVersionHB) {
        baseUrl = `${this.$store.getters.hbUrl}${baseUrl}`
      }

      location.href = baseUrl;
    },
    productsClicked(productTypeId) {
      let productName;

      switch(productTypeId) {
        case 1:
          productName = 'select_car_insurance';
          break;
        case 2:
          productName = 'select_home_insurance'
          break;
        case 3:
          productName = 'select_health_insurance'
          break;
        case 4:
          productName = 'select_travel_insurance'
          break;
        case 5:
          productName = 'select_fin_insurance'
          break;
        default:
          productName = 'undefined'
          break;
      }

      eventLogger(productName);
    },
  }
}
</script>


<style lang="scss">
@import "../../sass/variables";
.icon-message{
  margin-right: 20px;
}
.banner-loading {
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.banner {
  height: auto;
  margin-bottom: 16px;
  background-size: cover !important;
  background-position-y: bottom !important;
  width: 100vw !important;
  max-width: 768px;

  .banner__container {
    padding: 16px;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    min-height: 50vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
    .left-side {
      width: 180px;
    }
    .title {
      color: #fff;
      font-size: 18px;
      font-weight: 800;
      line-height: 20px;
    }

    .desc {
      color: #fff;
      font-size: 14px;
      font-weight: 800;
    }

    .btn {
      color: #222;
      font-size: 16px;
    }
  }
}

.welcome-page {
  .insurance-types {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 0 16px;

    .insurance-types__item {
      background: #FFFFFF;
      box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 16px;
      &__container {
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .gallery{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      .discount-badge{
        text-align: center;

        color:white;
        background: #FD2D39;
        /* White */

        border: 1px solid #FFFFFF;
        border-radius: 21px;

        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        //line-height: 12px;

        padding: 2px 8px;
        margin-right: auto;
      }
      .title {
        color: #2A353D;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
      }
      .subtitle {
        color: #2A353D;
        opacity: .5;
        font-size: 14px;
        margin: 5px 0 0;
      }
      .img {
        align-self: flex-end;
        max-width: 100px;
        height: 50px;
        object-fit: contain;
        margin-right: -16px;
        margin-bottom: -16px;
      }
      .text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
      }
    }
  }
}

@media (max-width: 768px) {
  .welcome-page {
    .insurance-types {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .banner {
    .banner__container {
      /*min-height: 40vh;*/
      min-height: 250px;
    }
  }
  .banner-loading {
    min-height: 40vh;
  }
}
@media (max-width: 555px) {
  .welcome-page {
    .insurance-types {
      grid-template-columns: repeat(1, 1fr);
      .insurance-types__item  {
        img {
          width: 80%;
          height: 50px;
        }
      }
    }
  }
  .banner {
    .banner__container {
      /*min-height: 30vh;*/
      min-height: 160px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 30vh;
  }
}
@media (max-width: 430px) {
  .banner {
    .banner__container {
      min-height: 120px;
      max-height: 120px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 120px;
    max-height: 120px;
  }
}

@media (max-width: 380px) {
  .banner {
    .banner__container {
      min-height: 110px;
      max-height: 110px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 110px;
    max-height: 110px;
  }
}

.banner-slider-wrap{
  margin: 0px 20px;
  margin-bottom: 16px;
  .slick-list{
    border-radius: 12px;
    overflow: hidden;
  }
  .slick-slider{
    padding-bottom: 30px;
  }
  .slick-dots{
    bottom: 0px;
    li{
      width: 8px;
      height: 8px;
      padding: 0px;
      margin: 0 8px;
      button{
        width: 8px;
        height: 8px;
        padding: 0px;
        &:before{
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #D3D7DE;
          opacity: 1;
        }
      }
      &.slick-active{
        button{
          &:before{
            background: #2AA65C;
          }
        }
      }
    }
  }
}
.banner {
  margin-bottom: 0px;
}

.banner-slider-wrap.redesign .banner .banner__container{
  /* min-height: 120px;
   max-height: 120px;*/
  .left-side{
    .title{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
    }
    .subtitle{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
      text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
      b{
        color: #fff;
        font-size: 24px;
      }
    }
  }
}


</style>
