<template>
    <div>
      <template v-if="storiesLoading">
        <div class="pb-3 ps-3 d-flex">
          <div class="skeleton skeleton-story" v-for="(item, index) in 3" :key="index"></div>
        </div>
      </template>
      <div v-else id="stories" class="storiesWrapper" v-stories="stories"></div>
    </div>
</template>

<script>

export default {
  name: "TheStories",
  data() {
    return {
      stories: [],
      preloadImages: {
        list: []
      },
      storiesLoading: true
    }
  },
  mounted() {
    this.getStories();
  },
  methods: {
    getStories() {
      try{
      axios.get('stories').then(({ data }) => {
         this.stories = data;

         const list = [];

         for (let key in data) {
           for(let itemKey in data[key].items){
             list.push(data[key]['items'][itemKey].src)
           }
         }

         this.preloadStoryImages(list)

         this.storiesLoading = false;
       })
    }catch(e){
        console.log(e);
    }
    },

    preloadStoryImages(srcs) {
      const {preloadImages} = this;
      if (!preloadImages.cache) {
        preloadImages.cache = [];
      }
      var img;
      for (var i = 0; i < srcs.length; i++) {
        img = new Image();
        img.src = srcs[i];
        preloadImages.cache.push(img);
      }
    }
  },
}
</script>

<style lang="scss">
@import "../sass/variables";
@import "src/plugins/zuck-stories/zuck.min";

.skeleton-story {
  width: 120px;
  height: 124px;
  border-radius: 12px;
  margin-right: 6px;
  &:last-child {
    margin-right: 0;
  }
}

.storiesWrapper {
  margin-bottom: 16px;
  padding-left: 16px;
}

.stories.carousel {
  white-space: unset !important;
  display: flex;
}

.story-preview-text {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  strong {
    color: #FCB016;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
  }
}

.storiesWrapper::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}

.stories.carousel .story {
  min-width: 120px;
  max-width: 120px;
  margin: 0 5px;
}

.stories.carousel .story > .item-link > .item-preview {
  height: 120px;
  max-height: 120px;
}


.stories.carousel .story > .item-link {
  border-radius: 12px;
}

.stories.carousel .story.green > .item-link {
  border: 2px solid $primary-color;
}

.stories.carousel .story.orange > .item-link {
  border: 2px solid #FCB016;
}

.stories.carousel .story.seen > .item-link {
  border: 2px solid #C8C7CC;
}

.stories.snapgram .story.seen > a > .item-preview {
  background: inherit;
}

.stories.snapgram .story > .item-link > .item-preview {
  border-radius: 12px;
  padding: 1px;
  background: #fff;
}

.stories.snapgram .story > .item-link > .item-preview .item-wrap {
  border-radius: 10px;
  border: 2px solid #fff;
  width: 100%;
  height: 100%;
  padding: 5px;
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.stories.carousel .story > .item-link > .info {
  display: none;
}

.story-viewer {
  div.left, div.right {
    display: none !important;
  }
}

.story-viewer {
  div.left, div.right {
    display: none !important;
  }
}

.tip.muted {
  top: 20px !important;
  right: 0 !important;
  height: 30px;
  width: fit-content !important;
}

a.tip {
  bottom: -5px !important;
}

a.tip.link {
  background: unset !important;
  font-weight: bold !important;
  position: relative;
  .arrow {
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
  }
}
</style>
